import eventsHandler from '@/services/common/eventsHandler.js'

class paymentCreditForm extends eventsHandler {
  constructor() {
    super()
    this._paymentCredit = {}
    this._valid = false
  }

  get paymentCredit() {
    const paymentCredit = {
      ...this._paymentCredit
    }
    return paymentCredit
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._paymentCredit.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetPaymentCredit() {
    this._paymentCredit = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {paymentCredit: {}})
  }

  updateField(key, value) {
    this._paymentCredit[key] = value
    this._executeCallbacksOf('update', {paymentCredit: this._paymentCredit})
    this._executeCallbacksOf('update-' + key, {key: this._paymentCredit[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(paymentCredit) {
    this._paymentCredit = paymentCredit
    this._executeCallbacksOf('update', {paymentCredit: this._paymentCredit})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new paymentCreditForm()